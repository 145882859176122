import video1 from '../images/videos/dina_videos1.mp4'
import video2 from '../images/videos/dina_videos2.mp4'

const videoData = [
  { 
    id: 1,
    video: `${video1}`,
  },
  { 
    id: 2,
    video: `${video2}`,
  },
]

export default videoData;